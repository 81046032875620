.mainContainer {
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 10px;
  align-items: center;
  background: center / cover;
  overflow: hidden;
  padding: 12px 22px 0px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.header {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 40px;
  height: fit-content;
  background: var(--Surface-background-secondary, #eff1f3);
  border-radius: 0.2rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  gap: 0px;
}
.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.headerSpan {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  height: 22px;
  padding: 44px, 16px, 44px, 16px;
  margin-left: 12px;
  margin-top: 10px;
}
.backButton {
  background: var(--Surface-background-primary, #ffffff);
  height: 24px;
  width: 24px;
  border: 2px solid var(--Border-border-default, #d8d8d8);
  border-radius: 0.2rem;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #7a7a7a;
}

.backButton:hover {
  background-color: #000000;
  color: #fff;
  border-radius: 2px;
}

.mainH {
  margin: 0px 0 0 35px;
  padding: 0;
  width: 100%;
  height: 81vh;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--Border-border-light, #e1e1e1);
}
.mainHead {
  width: 100%;
  height: 10%;
  padding: 2px 16px;
  background-color: #eff1f3;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.registrationContainer {
  border-radius: 5px;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  padding: 24px 32px 10px 0;
  margin-bottom: 50px;
  margin-top: 15px;
  margin-left: 20%;
  margin-right: 20%;
  height: 90%;
  background-color: #eff1f3;
}

.form {
  margin-top: 70px;
  margin-left: 0px;
}

.formGroup {
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  width: 100%;
}

.label {
  margin-bottom: 5px;
  flex: 1;
  text-align: left;
  margin-left: 30px;

  font-weight: 400;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 4px 4px 4px 75px;
}

.input {
  flex: 1;
  text-align: left;
}

.formGroup input[type="text"],
.selectorInput {
  padding: 7px 7px 7px 7px;
  border: 1px solid;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: start;
  border-color: #d8d8d8;
  outline: none;
}

.input:focus,
.selectorInput:focus,
.input:active,
.selectorInput:active {
  border-color: #2587f7;
}

.input[readonly] {
  text-align: start;
  padding: 7px;
  border: 1px solid;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fafafa;
  color: #929292;
  border-color: #d8d8d8 !important;
  outline: none;
}

.selectorInput {
  cursor: pointer;
  flex: 1;
}

.submitButtonContainer {
  display: flex;
  justify-content: center;
}

.submitButton {
  background-color: #2587f7;
  color: #fff;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #2587f7;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 50px;
  padding: 10px 110px 10px 110px;
  font-size: 14px;
}

.submitButton:hover {
  background-color: #195eac;
  color: #fff;
}
.spinner {
  border: 0.25rem solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 3.125rem;
  align-items: center;
  animation: spin 5s linear infinite;
  position: absolute;
  top: 4%;
  left: 35%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.container {
  height: 40px;
  width: 501px;
  align-items: start;
  cursor: pointer;
  font-family: "Nunito Sans";
  font-size: 12px;
}

.selectorContainer {
  border: 1px solid var(--Border-border-light, #e1e1e1);
  display: flex;
  flex-direction: column;
}

.selectorContainerExpanded {
  background-color: var(--Border-border-light, #e1e1e1);
}

.businessTitle {
  font-size: 11px;
  margin-left: 0px;
  padding: 4px 12px 0 12px;
}
.div {
  display: flex;
  justify-content: space-between;
}
.icon {
  margin-top: 10px;
  margin-right: 5px;
}

.scheduleName {
  border: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  color: inherit;
  width: 420px;
}
.scheduleName option:first-child {
  display: none;
}
.selector {
  width: 480px;
  height: 40px;
  padding: 0px 0;
  margin: 0px 0 0 5px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border-radius: 3px;
  top: 57px;
  z-index: 5;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 1;
  top: 60px;
  right: 0;
}

.dropdown {
  width: 500px;
  height: 500px;
  padding: 5px 0;
  margin: 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  border-top: none;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  top: 57px;
  z-index: 5;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdownItem {
  padding: 1px 15px;
  cursor: pointer;
  margin: 2px 2px;
  font-size: 12px;
}

.selectedDropdownItem {
  font-weight: bold;
}

.dropdownItem:hover {
  background-color: #3a86ff;
  color: #fff;
  border-radius: 3px;
}

.scheduleAndFilter {
  display: flex;
  padding: 0 12px 4px 12px;
}

.selectedSchedule {
  display: block;
  width: 310px;
  white-space: nowrap;
  font-size: 12px;
}

.searchDiv {
  display: block;
}

.filterInput {
  position: relative;
  border: 1px;
  left: 10px;
  top: -10px;
  width: 140px;
  height: 20px;
  font-size: 11px;
  padding: 1px;
}

.navBar {
  background-color: #fff;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  position: relative;
}
.selector {
  margin-left: 0px;
}
.navList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navItem {
  margin-right: 20px;
  margin-bottom: 10px;
}

.navItemIcon {
  margin: 5px auto 20px 10px;
  cursor: pointer;
}

.navItemIcon img {
  margin-right: 20px;
  height: 38px;
  width: 37px;
  margin-top: 20px;
  margin-left: 5px;
}

.navItem a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}
.navItem a:hover {
  color: #00bcd4;
}
.navItemProfile {
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.profileWrapper {
  position: relative;
  display: inline-block;
}

.profileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  color: #a9a9a9;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 1;
  top: 60px;
  right: 0;
}

.anchor {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.button {
  background: none;
  border: none;
  color: #fff;
  padding: 12px 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.anchor:hover {
  background-color: #555;
}
.dropdown {
  position: absolute;
  width: 160px;
  height: max-content;
  padding: 5px 0;
  margin: 2px 0 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  top: 57px;
  right: 0;
  z-index: 5;
}

.dropdownItem {
  padding: 5px 10px;
  cursor: pointer;
  margin: 4px 8px;
  font-size: 14px;
}

.dropdownItem:hover {
  background-color: #3a86ff;
  color: #fff;
  border-radius: 3px;
}
.dropdownItemVer {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  margin: 4px 8px;
  color: rgb(208, 208, 208);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

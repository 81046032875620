.container {
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 10px;
  align-items: center;
  background: center / cover;
  padding: 12px 22px 0px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
.header {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 40px;
  height: fit-content;
  background: var(--Surface-background-secondary, #eff1f3);
  border-radius: 0.2rem;
  border: 1px solid var(--Border-border-light, #e1e1e1);
  gap: 0px;
}
.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerSpan {
  font-size: 19px;
  font-weight: 600;
  line-height: 21.82px;
  height: 30px;
  padding: 44px, 16px, 44px, 16px;
  margin-top: 10px;
  margin-left: 10px;
}

.fetchButton {
  margin-right: 15px;
  border-radius: 4px;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  padding: 8px 9px 8px 9px;
}

.fetchButtonLoading {
  color: gray;
}


.mainTable {
  margin: 0px 0 0 35px;
  padding: 0;
  width: 100%;
  height: 81vh;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--Border-border-light, #e1e1e1);
}

.tableBody {
  width: 99%;
  height: 98%;
  background-color: #fff;
  margin: 0.4rem 0.4rem 0.4rem 0.4rem;
  border-radius: 0.6rem;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

.table th {
  padding: 1rem 1rem 1rem 115px;
  text-align: left;
  transition: 1s;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
}
.table td {
  padding: 1rem 1rem 1rem 115px;
  text-align: left;
  transition: 1s;
  border-bottom: 1px solid var(--Border-border-default, #d8d8d8);
}

.tHead th {
  background-color: #e9f2f2;
}

.bodyTr:nth-child(even) {
  background-color: #0000000b;
}
.bodyTr:hover {
  cursor:pointer;
}

.active {
  border-radius: 3px;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding: 6px, 12px, 6px, 12px;
  color: #000;
  background: var(--Pill-Success-bg-alt, #dae8bd);
  text-align: center;
}

.inactive {
  border-radius: 3px;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding: 6px, 12px, 6px, 12px;
  color: #000;
  background: var(--Pill-Success-bg-alt, #d893a3);
  text-align: center;
}
@media (max-width: 1000px) {
  td:not(:first-of-type) {
    min-width: 12.1 rem;
  }
}
.spinner {
  text-align: center;
  padding: 5px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  justify-content: center;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
